a.navbar-brand {
  white-space: normal;
  text-align: center;
  word-break: break-all;
}

html {
  font-size: 14px;
}
@media (min-width: 768px) {
  html {
    font-size: 16px;
  }
}

.box-shadow {
  box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
}

.LoginPage {
    display: -webkit-flex;
    display: flex;
    padding-top: 50px;
    padding-right: 50px;
    padding-left: 50px;
    -webkit-align-items: center;
            align-items: center;
    -webkit-flex-direction: column;
            flex-direction: column;
    margin-top: 50px;
    margin-left: 50px;
    margin-right: 50px;
    
}
.TextFieldStyle{
    width: 70%;
    margin-bottom: 40px;
}
.ButtonPage {
  width: 200px;
}
.ForgotButtonDiv{
  padding-left: 220px;
}
.LoginButtonDiv{
  padding-top: 10px;
  padding-bottom: 10px;
}
.ForgotPasswordButton{ 
  width: 20%;
}
.BodyForm {
    
    display: -webkit-flex;
    
    display: flex;
    padding-top: 10px;
    padding-bottom: 50px;
    -webkit-align-items: left;
            align-items: left;
    -webkit-flex-direction: row;
            flex-direction: row;
    margin-right: 50px;

    

}
.Body {
    display: -webkit-flex;
    display: flex;
    padding-top: 10px;
    -webkit-align-items: left;
            align-items: left;
    -webkit-flex-direction: column;
            flex-direction: column;
    margin-right: 50px;
    
}
.LeftSide {
    display: -webkit-flex;
    display: flex;
    padding-top: 10px;
    padding-right: 50px;
    -webkit-align-items: left;
            align-items: left;
    -webkit-flex-direction: column;
            flex-direction: column;
    margin-right: 50px;
    
}
.BodyForm {
    
        display: -webkit-flex;
    
        display: flex;
        padding-top: 10px;
        padding-bottom: 50px;
        -webkit-align-items: left;
                align-items: left;
        -webkit-flex-direction: row;
                flex-direction: row;
        margin-right: 50px;
    
        
    
}
.ComponentDiv{
    margin-top: 10px;
    padding-top: 15px;
}
.TableSelect{
  margin-top: 10px;
  padding-top: 15px;
}
.TextFieldStyle{
    width: 70%;
    margin-bottom: 40px;
}
.ButtonPage {
  width: 200px;
}
.ForgotButtonDiv{
  padding-left: 220px;
}
.LoginButtonDiv{
  padding-top: 10px;
  padding-bottom: 10px;
}
.ForgotPasswordButton{ 
  width: 20%;
}
.ButtonDelete{
  padding-left: 0px;
}
.column {
  float: left;
  width: 30%;
  margin-left: 12px;
  padding: 2px;
  text-align: center;
  font-size: 25px;
  cursor: pointer;
  color: white;
}

/* Clear floats after the columns */
.row:after {
  padding: 2px;
  margin: 2px;
  content: "";
  display: table;
  clear: both;
}
.Body {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    padding-top: 10px;
    -webkit-align-items: left;
            align-items: left;
    -webkit-flex-direction: column;
            flex-direction: column;
    margin-right: 50px;   
}
.Select{
  display: -webkit-flex;
  display: flex;
  padding-top: 10px;
  -webkit-align-items: left;
          align-items: left;
  -webkit-flex-direction: column;
          flex-direction: column;
  margin-right: 50px;
  min-width: 500px;
  max-width: 100%;
}
.LeftSide {
    display: -webkit-flex;
    display: flex;
    padding-top: 10px;
    padding-right: 50px;
    -webkit-align-items: left;
            align-items: left;
    -webkit-flex-direction: column;
            flex-direction: column;
    margin-right: 50px;
    
}
.column {
  float: left;
  width: 30%;
  margin-left: 12px;
  padding: 2px;
  text-align: center;
  font-size: 25px;
  cursor: pointer;
  color: white;
}

/* Clear floats after the columns */
.row:after {
  padding: 2px;
  margin: 2px;
  content: "";
  display: table;
  clear: both;
}

.formControl {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: column;
          flex-flow: column;
  margin: 2px;
  max-width:  300px;
}
.BodyForm {
    
        display: -webkit-flex;
    
        display: flex;
        padding-top: 10px;
        padding-bottom: 50px;
        -webkit-align-items: left;
                align-items: left;
        -webkit-flex-direction: row;
                flex-direction: row;
        margin-right: 50px;   
}

.chip{
  padding: 2px;
  margin : 2px;
}
.ComponentDiv{
    margin-top: 10px;
    padding-top: 15px;
}
.TableSelect{
  margin-top: 10px;
  padding-top: 15px;
}
.TextFieldStyle{
    width: 70%;
    margin-bottom: 40px;
}
.ButtonPage {
  width: 200px;
}
.ForgotButtonDiv{
  padding-left: 220px;
}
.LoginButtonDiv{
  padding-top: 10px;
  padding-bottom: 10px;
}
.ForgotPasswordButton{ 
  width: 20%;
}
.LoginPage {
    display: -webkit-flex;
    display: flex;
    padding-top: 50px;
    padding-right: 50px;
    padding-left: 50px;
    -webkit-align-items: center;
            align-items: center;
    -webkit-flex-direction: column;
            flex-direction: column;
    margin-top: 50px;
    margin-left: 50px;
    margin-right: 50px;
    
}
.TextFieldStyle{
    width: 70%;
    margin-bottom: 40px;
}
.ButtonPage {
  width: 200px;
}
.ForgotButtonDiv{
  padding-left: 220px;
}
.LoginButtonDiv{
  padding-top: 10px;
  padding-bottom: 10px;
}
.ForgotPasswordButton{ 
  width: 20%;
}
.MainPage {
    display: -webkit-flex;
    display: flex;
    padding-top: 50px;
    padding-right: 50px;
    padding-left: 50px;
    -webkit-align-items: center;
            align-items: center;
    -webkit-flex-direction: column;
            flex-direction: column;
    margin-top: 50px;
    margin-left: 50px;
    margin-right: 50px;
    
}
.Picker{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row;
            flex-flow: row
}
.UploadFile{
    padding: 10px;    
}

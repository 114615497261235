.LoginPage {
    display: flex;
    padding-top: 50px;
    padding-right: 50px;
    padding-left: 50px;
    align-items: center;
    flex-direction: column;
    margin-top: 50px;
    margin-left: 50px;
    margin-right: 50px;
    
}
.TextFieldStyle{
    width: 70%;
    margin-bottom: 40px;
}
.ButtonPage {
  width: 200px;
}
.ForgotButtonDiv{
  padding-left: 220px;
}
.LoginButtonDiv{
  padding-top: 10px;
  padding-bottom: 10px;
}
.ForgotPasswordButton{ 
  width: 20%;
}
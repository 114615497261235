.ButtonDelete{
  padding-left: 0px;
}
.column {
  float: left;
  width: 30%;
  margin-left: 12px;
  padding: 2px;
  text-align: center;
  font-size: 25px;
  cursor: pointer;
  color: white;
}

/* Clear floats after the columns */
.row:after {
  padding: 2px;
  margin: 2px;
  content: "";
  display: table;
  clear: both;
}
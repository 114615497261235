.Body {
    display: flex;
    flex-wrap: wrap;
    padding-top: 10px;
    align-items: left;
    flex-direction: column;
    margin-right: 50px;   
}
.Select{
  display: flex;
  padding-top: 10px;
  align-items: left;
  flex-direction: column;
  margin-right: 50px;
  min-width: 500px;
  max-width: 100%;
}
.LeftSide {
    display: flex;
    padding-top: 10px;
    padding-right: 50px;
    align-items: left;
    flex-direction: column;
    margin-right: 50px;
    
}
.column {
  float: left;
  width: 30%;
  margin-left: 12px;
  padding: 2px;
  text-align: center;
  font-size: 25px;
  cursor: pointer;
  color: white;
}

/* Clear floats after the columns */
.row:after {
  padding: 2px;
  margin: 2px;
  content: "";
  display: table;
  clear: both;
}

.formControl {
  display: flex;
  flex-flow: column;
  margin: 2px;
  max-width:  300px;
}
.BodyForm {
    
        display: flex;
        padding-top: 10px;
        padding-bottom: 50px;
        align-items: left;
        flex-direction: row;
        margin-right: 50px;   
}

.chip{
  padding: 2px;
  margin : 2px;
}
.ComponentDiv{
    margin-top: 10px;
    padding-top: 15px;
}
.TableSelect{
  margin-top: 10px;
  padding-top: 15px;
}
.TextFieldStyle{
    width: 70%;
    margin-bottom: 40px;
}
.ButtonPage {
  width: 200px;
}
.ForgotButtonDiv{
  padding-left: 220px;
}
.LoginButtonDiv{
  padding-top: 10px;
  padding-bottom: 10px;
}
.ForgotPasswordButton{ 
  width: 20%;
}
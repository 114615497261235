.MainPage {
    display: flex;
    padding-top: 50px;
    padding-right: 50px;
    padding-left: 50px;
    align-items: center;
    flex-direction: column;
    margin-top: 50px;
    margin-left: 50px;
    margin-right: 50px;
    
}
.Picker{
    display: flex;
    flex-flow: row
}
.UploadFile{
    padding: 10px;    
}